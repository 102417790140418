import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { ProjectMilestonesConfig } from "./types"

export const PROJECT_STATUSES: ProjectMilestonesConfig<ProjectStatus> = [
  {
    id: ProjectStatus.SIGNED,
    label: "Projet signé",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [ProjectStatus.REVIEWED],
  },
  {
    id: ProjectStatus.REVIEWED,
    label: "Après signature",
    color: "blue",
    nodePosition: { x: 200, y: 50 },
    nextSteps: [ProjectStatus.TECHNICAL_VISIT_SCHEDULED],
  },
  {
    id: ProjectStatus.TECHNICAL_VISIT_SCHEDULED,
    label: "Visite technique planifiée",
    color: "blue",
    nodePosition: { x: 400, y: 40 },
    nextSteps: [ProjectStatus.TECHNICAL_VISIT_COMPLETED],
  },
  {
    id: ProjectStatus.TECHNICAL_VISIT_COMPLETED,
    color: "blue",
    label: "Visite technique validée",
    nodePosition: { x: 600, y: 40 },
    nextSteps: [ProjectStatus.INSTALLATION_SCHEDULED],
  },
  {
    id: ProjectStatus.INSTALLATION_SCHEDULED,
    label: "Installation planifiée",
    color: "blue",
    nodePosition: { x: 800, y: 50 },
    nextSteps: [ProjectStatus.INSTALLATION_COMPLETED],
  },
  {
    id: ProjectStatus.INSTALLATION_COMPLETED,
    label: "Installation réalisée",
    color: "blue",
    nodePosition: { x: 1000, y: 50 },
    nextSteps: [ProjectStatus.CONSUEL_VALIDATED],
  },
  {
    id: ProjectStatus.CONSUEL_VALIDATED,
    label: "Consuel validé",
    color: "blue",
    nodePosition: { x: 1200, y: 50 },
    nextSteps: [ProjectStatus.DONE],
  },
  {
    id: ProjectStatus.DONE,
    label: "Projet terminé",
    color: "green",
    nodePosition: { x: 1400, y: 50 },
    nextSteps: [],
  },
  {
    id: ProjectStatus.ABORTED,
    label: "Projet annulé",
    color: "red",
    nextSteps: [],
  },
]

export const STATUSES_COLOR = "yellow.8"
export const STATUSES_VIEW_ID = "status"
