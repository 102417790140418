import { ProjectStatus as PrismaProjectStatus } from "@ensol/types/prisma-client/index.js"

import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { getFullName } from "@ensol/shared/entities/prospects"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const Overview = ({ project }: InfoComponentProps) => {
  const {
    salesOwner,
    operationsOwner,
    adminOwner,
    technicalVisitInstaller,
    installer,
    signatureDate,
    technicalVisitStartDate,
    installationEndDate,
    orderDeliveryDate,
  } = project

  return (
    <ProcessInfos
      infos={[
        {
          name: "Respo. AE",
          value: salesOwner ? getFullName(salesOwner) : null,
        },
        {
          name: "Respo. Ops",
          value: operationsOwner ? getFullName(operationsOwner) : null,
        },
        {
          name: "Respo. Admin",
          value: adminOwner ? getFullName(adminOwner) : null,
        },
        {
          name: "Installateur",
          value: installer?.name ?? technicalVisitInstaller?.name,
        },
        {
          name: "Date signature",
          value: formatDate(signatureDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: project.status !== ProjectStatus.REVIEWED,
        },
        {
          name: "Date VT",
          value: formatDate(technicalVisitStartDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: project.status !== ProjectStatus.TECHNICAL_VISIT_SCHEDULED,
        },
        {
          name: "Installation",
          value: formatDate(installationEndDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: project.status !== ProjectStatus.INSTALLATION_SCHEDULED,
        },
        {
          name: "Livraison matériel",
          value: formatDate(orderDeliveryDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: project.status !== ProjectStatus.INSTALLATION_SCHEDULED,
        },
      ]}
      milestones={{
        processes: getProcessesToDisplayByProjectStatus(project.status),
        project,
      }}
    />
  )
}

const getProcessesToDisplayByProjectStatus = (
  status: PrismaProjectStatus,
): ProcessId[] => {
  switch (status) {
    case ProjectStatus.REVIEWED:
    case ProjectStatus.TECHNICAL_VISIT_SCHEDULED:
      return ["preliminaryRequestStep", "technicalVisitStep"]
    case ProjectStatus.TECHNICAL_VISIT_COMPLETED:
      return ["preliminaryRequestStep", "installationStep"]
    case ProjectStatus.INSTALLATION_SCHEDULED:
      return ["installationStep", "hardwareStep", "paymentStep"]
    case ProjectStatus.INSTALLATION_COMPLETED:
      return ["paymentStep", "installationStep", "enedisStep", "consuelStep"]
    case ProjectStatus.CONSUEL_VALIDATED:
      return ["installationStep", "enedisStep"]
    case ProjectStatus.DONE:
      return ["edfOaStep", "greendealStep"]
    default:
      return []
  }
}
